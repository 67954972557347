@tailwind base;
@tailwind components;
@tailwind utilities;

html {
  height: 100%;
}

#root {
  height: 100%;
}

body {
  margin: 0;
  height: 100%;
  background: #eff3f6;
}

.aside {
  width: 240px;
}

.step-progress {
  position: relative;
}

.step-progress:before {
  content: '';
  position: absolute;
  z-index: -1;
  top: 1.6em;
  left: 12px;
  bottom: 0;
  width: 1px;
  @apply bg-slate-300;
}

.switch {
  margin-top: 0.1rem;
  background-image: url('data:image/svg+xml,%3Csvg xmlns=%22http://www.w3.org/2000/svg%22 viewBox=%22-4 -4 8 8%22%3E%3Ccircle r=%223%22 fill=%22%23fff%22/%3E%3C/svg%3E');
  background-position: 0;
  transition: background-position 0.15s ease-in-out;
}

.switch:checked {
  background-position: 100%;
  background-image: url('data:image/svg+xml,%3Csvg xmlns=%22http://www.w3.org/2000/svg%22 viewBox=%22-4 -4 8 8%22%3E%3Ccircle r=%223%22 fill=%22%23fff%22/%3E%3C/svg%3E');
}
